import { observable, action, runInAction, computed, toJS } from 'mobx';
import axios from 'axios';
import authStore from './authStore';

export default class DataStore {
  @observable state = 'loading';
  @observable vendorInfo = null;
  @observable experienceTransactions = null;

  //   @computed
  //   get vendorInfo(){

  //   }

  @action
  async retrieveExperienceTransactions() {
    try {
      const data = await this.fetchExperienceTransactions();
      runInAction(() => {
        this.experienceTransactions = data.docs;
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  async retrieveVendorInfo() {
    try {
      const data = await this.fetchVendorInfo();
      runInAction(() => {
        this.vendorInfo = data.docs[0];
      });
    } catch (e) {
      console.error(e);
    }
  }

  fetchVendorInfo = async () => {
    const { data } = await axios.get(
      `https://frozen-woodland-21144.herokuapp.com/app/vendors?token=${authStore.userToken}`
    );
    return data;
  };

  fetchExperienceTransactions = async () => {
    await this.retrieveVendorInfo();
    const { data } = await axios.get(
      `https://frozen-woodland-21144.herokuapp.com/app/transactions/vendor/${this.vendorInfo._id}?token=${authStore.userToken}&pageN=1&limitN=100&sortField1=dateCreated&sortOrder1=-1`
    );

    return data;
  };
}
