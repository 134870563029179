import React from 'react';
import { css, cx } from 'emotion';

const Container = css`
  height: 160px;
  width: 250px;
  background: white;
  border-radius: 1.2rem;
  border: 5px solid #666;
  overflow: hidden;
  margin-bottom: 0px;
  z-index: 10;
  @media (max-width: 1080px) {
    margin: 1.5rem 0px;
  }
`;

const TextContainer = css`
  margin: 0px 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #4e4e4e;
  font-size: 1.2rem;
`;

const NumberContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  border-radius: 100%;
  border: 7px solid #3b94e1;
  padding: 5px;
  height: 60px;
  width: 60px;
  position: absolute;
  font-size: 2rem;
  color: #666;
  font-weight: bold;
  margin-left: -25px;
  margin-top: -25px;
`;

const Step = ({ number, text }) => {
  return (
    <div className={Container}>
      <div className={NumberContainer}>{number}</div>
      <div className={TextContainer}>{text}</div>
    </div>
  );
};

export default Step;
