import React from 'react';
import { Route } from 'react-router-dom';
import Nav from '../components/Nav';

const UnAuthRoute = ({ component: PassedComponent, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Nav>
        <PassedComponent {...props} />
      </Nav>
    )}
  />
);

export default UnAuthRoute;
