import React, { useState, useEffect } from 'react';
import { css, cx } from 'emotion';
import Sidebar from '../Sidebar';
import ReactTable from 'react-table';
import { format } from 'date-fns';
import SaleModal from './SaleModal';
// import { dateSort } from '../../util/TableUtils';
import { Formik } from 'formik';
import { FaSearch } from 'react-icons/fa';
import { useRootStore } from '../../stores/BridgeProvider';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { useWindowSize } from '../../util/CustomHooks';

const DUMMY_DATA = [
  {
    transaction: '00012345',
    customer: 'John Smith',
    activity: 'Tandem Kayaking for 2',
    totalPaid: 20,
    date: new Date(),
    status: 1,
  },
  {
    transaction: '00012346',
    customer: 'Jane Doe',
    activity: 'Solo Kayaking',
    totalPaid: 15,
    date: new Date(),
    status: 0,
  },
];

const container = css`
  padding: 0 2rem;
  @media (max-width: 700px) {
    padding: 0 5px;

    #salesHistoryTitle {
      font-size: 1.3em;
    }
  }

  .topItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ReactTable {
    border: none;
    color: #666;
  }

  .ReactTable .rt-table {
    overflow: visible;
  }

  .rt-tr {
    border: none !important;
    background: white;
    margin: 0.5rem 0px;
    padding: 1rem 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);
    align-items: center;
  }

  .rt-tr-group {
    border: none !important;
  }
  .rt-td {
    border: none !important;
  }

  .rt-tbody {
    overflow: visible !important;
  }

  .rt-thead {
    border: none !important;
    box-shadow: none !important;
    overflow: visible !important;
    .rt-tr {
      padding: 0px !important;
    }
    .rt-th {
      height: 60px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
`;

const redeemButton = css`
  outline: none;
  cursor: pointer;
  border: none;
  color: white;
  background: #0f93e8;
  border-radius: 0.4rem;
  font-size: 1rem;
  padding: 0.4rem 0rem;
  width: 105px;
  @media (max-width: 700px) {
    font-size: 0.85rem;
    padding: 0.35rem 0rem;
    width: 80px;
  }
`;

const redeemDisabled = css`
  background: white;
  color: #bbbbbb;
  border: 1px solid #bbbbbb;
  cursor: default;
`;

const searchContainer = css`
  display: flex;
  flex-direction: row;
  margin: 2rem 0rem;
  justify-content: flex-end;
  align-items: center;
  span {
    margin: 0;
    margin-right: 1rem;
    font-size: 1.2rem;
  }
  input {
    outline: none;
    font-size: 1rem;
    padding: 8px 10px;
    min-width: 200px;
    border: none;
  }
  button {
    height: 34px;
    outline: none;
    cursor: pointer;
    border: none;
    color: #a0a0a0;
    background: #cecece;
    width: 40px;
    padding: 0px;
    font-size: 1rem;
    &:hover {
      background: #bfbfbf;
    }
  }
  .buttonInput {
    display: flex;
    border: 1px solid #cecece;
  }
`;

const SalesHistory = () => {
  const [redeemItem, setRedeemItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const { dataStore } = useRootStore();
  const screenWidth = useWindowSize();

  // const vTransactions = dataStore.experienceTransactions;

  useEffect(() => {
    const initialize = async () => {
      if (!dataStore.experienceTransactions) {
        await dataStore.retrieveExperienceTransactions();
        console.log(toJS(dataStore.experienceTransactions));
      }
      setLoading(false);
    };
    initialize();
  }, [dataStore]);

  if (loading) return null;

  const RedeemCell = ({ original, value }) => {
    const redeemable = value.some(({ count }) => count > 0);
    if (redeemable) {
      return (
        <button
          onClick={() => {
            setRedeemItem(original);
            setModalOpen(true);
          }}
          className={redeemButton}
        >
          Redeem
        </button>
      );
    }
    return (
      <button disabled className={cx(redeemButton, redeemDisabled)}>
        Redeemed
      </button>
    );
  };

  const priceFromCart = (cart) => {
    //FIXME: see if Ivan can return price
    //FIXME: sale price
    const sum = cart.reduce(function (accumulator, currentValue, currentIndex, array) {
      const { count, package: curPackage } = currentValue;
      const packageValue = count * curPackage.price.value;
      return accumulator + packageValue;
    }, 0);
    return `$${parseFloat(sum).toFixed(2)}`;
  };

  const columns = [
    {
      Header: 'Transaction #',
      // accessor: d => 'shortID: "zC5EYM-z"',
      accessor: 'shortID',
      id: 1,
      show: screenWidth > 900,
    },
    {
      Header: 'Customer',
      accessor: 'customer.name',
    },
    {
      Header: 'Activity',
      accessor: 'experience.name',
    },
    {
      Header: 'Total Paid',
      accessor: (d) => priceFromCart(d.cart),
      id: 4,
    },
    {
      Header: 'Date',
      accessor: (d) => format(new Date(d.dateCreated), 'yyyy/MM/dd'),
      // sortMethod: dateSort
      id: 5,
      show: screenWidth > 700,
    },
    {
      Header: 'Status',
      accessor: 'cart',
      Cell: RedeemCell,
    },
  ];

  return (
    <div className={container}>
      <div className="topItems">
        <h2 id="salesHistoryTitle">Sales History</h2>
        <Formik
          onSubmit={async (values, { setSubmitting }) => {
            //TODO: this function
            setRedeemItem(DUMMY_DATA[0]);
            setModalOpen(true);
            setSubmitting(false);
          }}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={searchContainer}>
                {screenWidth > 700 && <span>Search Voucher</span>}
                <div className="buttonInput">
                  <input
                    type="text"
                    placeholder="Enter code or email"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    type="submit"
                    disabled={isSubmitting || search.length < 1}
                    onClick={handleSubmit}
                  >
                    <FaSearch />
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div>
        <Observer
          render={() => (
            <ReactTable
              data={dataStore.experienceTransactions}
              columns={columns}
              minRows={1}
              defaultPageSize={15}
              pageSizeOptions={[15, 25, 50, 100]}
              showPagination={false}
              // filterable
              // defaultFilterMethod={(filter, row) => {
              //   const id = filter.pivotId || filter.id;
              //   return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
              // }}
            />
          )}
        />
      </div>
      <SaleModal open={modalOpen} controlOpen={setModalOpen} sale={redeemItem} />
    </div>
  );
};

export default SalesHistory;
