import React from 'react';
import Intro from './Intro';
import Introducing from './Introducing';
import Footer from './Footer';
import WhyPartner from './WhyPartner';
import HowToStart from './HowToStart';

const Home = () => {
  return (
    <div>
      <Intro />
      <Introducing />
      <WhyPartner />
      <HowToStart />
      <Footer />
    </div>
  );
};

export default Home;
