import React from 'react';
import { css } from 'emotion';
// import axios from 'axios';
import Sidebar from '../Sidebar';
// import { Formik } from 'formik';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MyAccountTab from './MyAccountTab';
import BusinessTab from './BusinessTab';
import Experiences from './Experiences';
import PaymentTab from './PaymentTab';

const Container = css`
  margin-top: 2rem;
  display: flex;
  flex: 1;
  flex-direction: column;

  .accountTab {
    background: #e7e7e7;
    display: inline-block;
    border: none;
    position: relative;
    list-style: none;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    margin: 0px 0.8rem;
    font-size: 1.3rem;
    padding: 0.7rem 2rem;
    color: #a9a9a9;
  }

  .accountTabSelected {
    background: white;
    color: #666;
    border-radius: 5px 5px 0 0;
  }

  @media (max-width: 700px) {
    .accountTab {
      font-size: 0.9rem;
      padding: 0.4rem 0.7rem;
      margin: 0px 0.3rem;
    }
    overflow-x: scroll;
    max-width: 100vw;
}
  }
  @media (max-width: 450px) {
    .accountTab {
      font-size: 0.75rem;
      padding: 0.3rem 0.5rem;
      margin: 0px 0.2rem;
    }
  }
`;

const WhiteDiv = css`
  flex: 1;
  flex-grow: 1;
  background: white;
  padding: 0 2rem;
  @media (max-width: 700px) {
    padding: 0 0.5rem;
  }
`;

const MyAccount = () => {
  return (
    <div className={Container}>
      <Tabs style={{ background: 'white', flex: 1 }} forceRenderTabPanel>
        <TabList style={{ borderBottom: 'none', margin: '0', background: '#f8f8f8' }}>
          <Tab className="accountTab" selectedClassName="accountTabSelected">
            Experiences
          </Tab>
          <Tab className="accountTab" selectedClassName="accountTabSelected">
            My Account
          </Tab>
          <Tab className="accountTab" selectedClassName="accountTabSelected">
            Business Info
          </Tab>
          <Tab className="accountTab" selectedClassName="accountTabSelected">
            Payments
          </Tab>
        </TabList>

        <TabPanel>
          <div className={WhiteDiv}>
            <Experiences />
          </div>
        </TabPanel>
        <TabPanel>
          <div className={WhiteDiv}>
            <MyAccountTab />
          </div>
        </TabPanel>
        <TabPanel>
          <div className={WhiteDiv}>
            <BusinessTab />
          </div>
        </TabPanel>

        <TabPanel>
          <div className={WhiteDiv}>
            <PaymentTab />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MyAccount;
