import React from 'react';
import { css } from 'emotion';
import backgroundBlur from './assets/backgroundBlur.jpg';
import BlueLogo from '../../assets/Logo-Blue-Outline.png';
import BlueTextLogo from '../../assets/BlueExploreTO.png';
import axios from 'axios';
import { useRootStore } from '../../stores/BridgeProvider';
import { Formik } from 'formik';

import * as Yup from 'yup';
import Spinner from './Spinner';

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required')
});

const LogosDiv = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

const BlueLogoStyle = css`
  height: 85px;
`;

const BlueTextLogoStyle = css`
  height: 30px;
`;

const Container = css`
  height: 100vh;
  background-image: url(${backgroundBlur});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  @media (max-width: 500px) {
    background: white;
  }
`;

const InsideContainer = css`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  align-items: center;
`;

const LoginCard = css`
  height: auto;
  width: 350px;
  background: white;
  border-radius: 1rem;
  padding: 0.5rem;
  text-align: center;
  h2 {
    font-family: BebasNeue;
    font-size: 2.5rem;
    margin-top: 0.5rem;
    color: #444;
  }
  form {
    display: flex;
    flex-direction: column;
    max-width: 310px;
    margin: 0 auto;
  }
  section {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0.8rem 0rem;
  }
  input {
    outline: none;
    font-size: 1rem;
    padding: 0.5rem 8px;
    border-radius: 0.3rem;
    border: 1px solid #666;
  }
  label {
    margin-bottom: 7px;
    font-family: 'AvenirMedium';
    color: #666;
  }
  .signInOptions {
    margin: 0.2rem 0rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      color: #666;
      font-size: 0.8rem;
    }
  }
  button {
    background: white;
    color: rgb(30, 136, 229);
    border-radius: 1rem;
    font-size: 1.2rem;
    padding: 0.4rem;
    outline: none;
    cursor: pointer;
    width: 160px;
    margin: 0 auto;
    margin-bottom: 2rem;
    border: 2px solid #1e88e5;
  }
  button:disabled,
  button[disabled] {
    background: #1e88e5;
    padding: 0.1rem 0.4rem;
  }
`;

const JoinUsText = css`
  color: #666;
  font-size: 0.8rem;
`;

const errorSpan = css`
  color: red;
  font-size: 10px;
`;

const Login = ({ history }) => {
  const { authStore } = useRootStore();
  const { onLogin } = authStore;
  return (
    <div className={Container}>
      <div className={InsideContainer}>
        <div className={LoginCard}>
          <div className={LogosDiv}>
            <img src={BlueLogo} alt="logo" className={BlueLogoStyle} />
            <img src={BlueTextLogo} alt="logo" className={BlueTextLogoStyle} />
          </div>
          <Formik
            initialValues={{ email: '', password: '', saveSignIn: true }}
            validationSchema={LoginSchema}
            onSubmit={async (values, { setSubmitting }) => {
              const url = 'https://frozen-woodland-21144.herokuapp.com/api/login';
              const { email, password } = values;
              const dataObject = {
                mode: 'login',
                email,
                password,
                name: ''
              };

              try {
                const { data } = await axios.post(url, dataObject);
                const acceptableValues = ['vendor', 'admin', 'superAdmin'];
                if (!acceptableValues.includes(data.type)) throw new Error('Wrong account type');
                onLogin(data, values.saveSignIn);
                setSubmitting(false);
                history.push('/sales-history');
              } catch (e) {
                console.log(e);
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched
            }) => (
              <form onSubmit={handleSubmit}>
                <section>
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <span className={errorSpan}>{errors.email}</span>
                  )}
                </section>
                <section>
                  <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password && (
                    <span className={errorSpan}>{errors.password}</span>
                  )}
                </section>
                <div className="signInOptions">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="saveSignIn"
                      checked={values.saveSignIn}
                      value={values.saveSignIn}
                    />
                    <span>Keep me signed in</span>
                  </div>
                  <span style={{ textDecoration: 'underline' }}>I forgot my password</span>
                </div>
                <button type="submit" disabled={isSubmitting} onClick={handleSubmit}>
                  {isSubmitting ? <Spinner color="white" /> : <span>Sign In</span>}
                </button>
              </form>
            )}
          </Formik>
          <span className={JoinUsText}>
            Not yet a partner? <span style={{ textDecoration: 'underline' }}>Join us!</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
