import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { css, cx } from 'emotion';
import BlueLogo from '../../assets/Logo-Blue-Outline.png';
import BlueTextLogo from '../../assets/BlueExploreTO.png';

const NavStyle = css`
  //   font-family: BebasNeue;
  background: white;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  div {
    margin: 0rem 1rem;
  }

  @media (max-width: 970px) {
    div {
      margin-right: 15px;
    }
  }
  @media (max-width: 430px) {
    height: 60px;
    a {
      font-size: 1.05rem;
    }
    div {
      margin-right: 10px;
    }
  }
`;

const NavLinkStyle = css`
  color: #a0a0a0;
  text-decoration: none;
  font-size: 1.15rem;
  margin: 0px 1rem;
  &:hover {
    color: #909090;
  }
  @media (max-width: 430px) {
    margin: 0px 0.5rem;
  }
`;

const Bubble = css`
  background: rgb(30, 136, 229);
  color: white;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  &:hover {
    color: white;
    background: rgb(23, 122, 210);
  }
  @media (max-width: 430px) {
    padding: 0.2rem 0.7rem;
  }
  @media (max-width: 380px) {
    display: none;
  }
`;

const LogoContainer = css`
  height: 60px;
  width: auto;
  display: flex;
  align-items: center;
  left: 10%;
  cursor: pointer;
  @media (max-width: 970px) {
    left: 15px;
  }
  @media (max-width: 430px) {
    left: 10px;
    height: 60px;
  }
`;
const BlueLogoStyle = css`
  height: 45px;
  margin-right: 12px;
  cursor: pointer;
  @media (max-width: 430px) {
    height: 40px;
  }
`;

const BlueTextLogoStyle = css`
  height: 25px;
  cursor: pointer;
  @media (max-width: 970px) {
    display: none;
  }
`;

const activeNav = css`
  //color: #1e88e5 !important;
`;

const Nav = ({ children, history }) => {
  return (
    <div>
      <header className={NavStyle}>
        <div className={LogoContainer} onClick={() => history.push('/')}>
          <img src={BlueLogo} alt="logo" className={BlueLogoStyle} />
          <img src={BlueTextLogo} alt="logo" className={BlueTextLogoStyle} />
        </div>
        <div>
          <NavLink exact to="/login" className={NavLinkStyle} activeClassName={activeNav}>
            Sign In
          </NavLink>
          <NavLink exact to="/login" className={NavLinkStyle} activeClassName={activeNav}>
            Support
          </NavLink>
          <NavLink
            exact
            to="/get-started"
            className={cx(NavLinkStyle, Bubble)}
            activeClassName={activeNav}
          >
            Get Started
          </NavLink>
        </div>
      </header>

      {children}
    </div>
  );
};

export default withRouter(Nav);
