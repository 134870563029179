import React, { useState } from 'react';
import { css } from 'emotion';
import { Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import Spinner from '../Login/Spinner';

const GetStartedSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phone: Yup.string().required('Required'),
  bizname: Yup.string().required('Required')
});

const Container = css`
  display: flex;
  padding-top: 60px;
  min-height: 100vh;
  height: auto;
`;

const InsideContainer = css`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  align-items: center;
  padding: 0px 15px;
`;

const LoginCard = css`
  height: auto;
  width: 100%;
  max-width: 1000px;
  background: white;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
  text-align: left;
  h2 {
    color: rgb(30, 136, 229);
    font-size: 2.5rem;
    margin-top: 0.5rem;
  }
  form {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 3rem;
    @media (min-width: 500px) {
      section:nth-child(even) {
        padding-left: 10px;
      }
      section:nth-child(odd) {
        padding-right: 10px;
      }
    }
  }
  section {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0.8rem 0rem;
    width: 100%;
  }
  input {
    outline: none;
    font-size: 1rem;
    padding: 0.5rem 8px;
    border-radius: 0.3rem;
    border: 1px solid #666;
  }
  label {
    margin-bottom: 7px;
    font-family: 'AvenirMedium';
    color: #666;
  }
  button {
    background: white;
    border-color: rgb(30, 136, 229);
    border-width: 1px;
    color: rgb(30, 136, 229);
    border-radius: 1rem;
    font-size: 1.2rem;
    padding: 0.4rem;
    outline: none;
    cursor: pointer;
    width: 160px;
    margin-top: 2rem;
    border-style: solid;
    border-image: none;
    @media (max-width: 500px) {
      margin: 0 auto;
    }
  }
  .formRow {
    display: flex;
    flex-direction: row;
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
`;

const errorSpan = css`
  color: red;
  font-size: 10px;
`;

const GetStarted = () => {
  const [showMessage, setShowMessage] = useState(false);
  return (
    <section className={Container}>
      <section className={InsideContainer}>
        <section className={LoginCard}>
          <h2>Let's get you started!</h2>
          <p>
            Tell us about your business by filling out the form below and a member of our team will
            get in touch with you within 24 hours.
          </p>
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              calltime: '',
              bizname: '',
              bizpostal: ''
            }}
            validationSchema={GetStartedSchema}
            onSubmit={async (values, { setSubmitting }) => {
              const url = 'https://frozen-woodland-21144.herokuapp.com/api/vendormessage';
              try {
                await axios.post(url, values);
                setShowMessage(true);
              } catch (e) {
                console.log(e);
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="formRow">
                  <section>
                    <label>Name *</label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <span className={errorSpan}>{errors.name}</span>
                    )}
                  </section>
                  <section>
                    <label>Email Address *</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <span className={errorSpan}>{errors.email}</span>
                    )}
                  </section>
                </div>
                <div className="formRow">
                  <section>
                    <label>Phone Number *</label>
                    <input
                      type="tel"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                    {errors.phone && touched.phone && (
                      <span className={errorSpan}>{errors.phone}</span>
                    )}
                  </section>
                  <section>
                    <label>Best time to call</label>
                    <input
                      type="text"
                      name="calltime"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.calltime}
                    />
                  </section>
                </div>
                <div className="formRow">
                  <section>
                    <label>Business Name *</label>
                    <input
                      type="text"
                      name="bizname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bizname}
                    />
                    {errors.bizname && touched.bizname && (
                      <span className={errorSpan}>{errors.bizname}</span>
                    )}
                  </section>
                  <section>
                    <label>Postal Code</label>
                    <input
                      type="text"
                      name="bizpostal"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bizpostal}
                    />
                  </section>
                </div>
                <button type="submit" disabled={isSubmitting} onClick={handleSubmit}>
                  {isSubmitting ? <Spinner color="white" /> : <span>Submit</span>}
                </button>
              </form>
            )}
          </Formik>
        </section>
        {showMessage && (
          <h3 style={{ color: 'rgb(30,136,229)' }}>
            Thank you for contacting ExploreTO Vendor relations.
          </h3>
        )}
      </section>
    </section>
  );
};

export default GetStarted;
