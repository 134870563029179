import React, { useState, useRef } from 'react';
import { css } from 'emotion';
import WhiteLogo from '../assets/Logo-White-Outline.png';
import WhiteTextLogo from '../assets/WhiteExploreTO.png';
import { useRootStore } from '../stores/BridgeProvider';
import { NavLink } from 'react-router-dom';
import { FaBars, FaRegBookmark, FaSignOutAlt } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { useOnClickOutside, useWindowSize } from '../util/CustomHooks';
import { Observer } from 'mobx-react';

const container = css`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const spacer = css`
  display: block;
  width: 260px;
  min-height: 100vh;
`;

const sidebarStyle = css`
  background: #0f93e8;
  height: 100vh;
  color: white;
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0rem;
  position: fixed;
  z-index: 99999;
  .menuOptions {
    width: 100%;
    a,
    div {
      padding: 1.5rem 2rem;
      display: block;
      font-size: 1.2rem;
      color: white;
      text-decoration: none;
    }
    .activeNavLink {
      background: #0080df;
    }
    .routeComingSoon {
      color: #2079c6;
    }
  }
`;

const contentStyle = css`
  background: #f8f8f8;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LogosDiv = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

const WhiteLogoStyle = css`
  height: 75px;
`;

const WhiteTextLogoStyle = css`
  height: 28px;
  margin-top: 5px;
`;

const navStripStyle = css`
  display: flex;
  border-bottom: 1px solid #ebeaea;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  h3 {
    color: #adadad;
    font-size: 1.5rem;
  }
  span {
    cursor: pointer;
    padding: 0px 1rem;
    color: #707070;
  }
`;

const ProfileDropdownStyle = css`
display: flex;
  flex-direction: row;
  align-items: center;
  #profileIcon {
    display: flex;
    align-items: center;
    color: #acacac;
    text-decoration: none;
    font-size: 1.5rem;
    margin-bottom: -3px;
    margin-right: 10px;
    &:hover {
      color: #909090;
      cursor: pointer;
    }
  }
  .profileMenu {

    z-index: 99999;
    position: absolute;
    top: 55px;
    left: 12px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    height: 160px;
    width: 180px;
    background: white;
    border-radius: 10px;
    padding: 0px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    }
  .profileMenuItem{
    height: 50px;
    border-bottom: solid 1px #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between;
      span{
        display: block;
        font-size: 1.2rem;
            color: #898383;
      }
      a{
            text-decoration: none;
        flex:1;
         display: flex;
        justify-content: space-between;
      }
      .dropdownIcon{
        margin-top: 6px;
      }
    }
  }
`;

const MobileDropdown = () => {
  const { authStore } = useRootStore();
  const ref = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  useOnClickOutside(ref, () => setShowDropdown(false));
  const { onLogout, userName } = authStore;
  return (
    <div className={ProfileDropdownStyle}>
      <FaBars id="profileIcon" onClick={() => setShowDropdown(true)} />
      <h3>{userName}</h3>
      {showDropdown && (
        <div className="profileMenu" ref={ref}>
          <div className="profileMenuItem">
            <NavLink to="/sales-history" onClick={() => setShowDropdown(false)}>
              <span>Sales History</span>
            </NavLink>
          </div>
          <div className="profileMenuItem">
            <NavLink to="/account" onClick={() => setShowDropdown(false)}>
              <span>My Account</span>
            </NavLink>
          </div>
          <div className="profileMenuItem" onClick={onLogout} style={{ cursor: 'pointer' }}>
            <span>Logout</span>
          </div>
        </div>
      )}
    </div>
  );
};

const BigSidebar = () => (
  <React.Fragment>
    <div className={spacer} />
    <div className={sidebarStyle}>
      <div className={LogosDiv}>
        <img src={WhiteLogo} alt="logo" className={WhiteLogoStyle} />
        <img src={WhiteTextLogo} alt="logo" className={WhiteTextLogoStyle} />
      </div>
      <div className="menuOptions">
        <NavLink activeClassName="activeNavLink" to="/sales-history">
          Sales History
        </NavLink>
        <NavLink activeClassName="activeNavLink" to="/account">
          My Account
        </NavLink>
        <div className="routeComingSoon">Data Analytics</div>
        <div className="routeComingSoon">Customer Feedback</div>
      </div>
    </div>
  </React.Fragment>
);

const Sidebar = ({ children }) => {
  const { authStore } = useRootStore();
  const screenWidth = useWindowSize();
  return (
    <Observer
      render={() => {
        const { onLogout, userName } = authStore;
        return (
          <div className={container}>
            {screenWidth >= 1080 && <BigSidebar />}
            <div className={contentStyle}>
              <div className={navStripStyle}>
                {screenWidth < 1080 && <MobileDropdown />}
                {screenWidth >= 1080 && <h3>{userName}</h3>}
                <div>
                  <span onClick={onLogout}>Sign Out</span>
                  <span>Support</span>
                </div>
              </div>
              {children}
            </div>
          </div>
        );
      }}
    />
  );
};

export default Sidebar;
