import React from 'react';
import { css } from 'emotion';
import bookingAsset from './assets/bookingAsset.png';
import visibilityAsset from './assets/visibilityAsset.png';
import insightAsset from './assets/insightAsset.png';

const Container = css`
  display: flex;
  justify-content: center;
`;

const MainContainer = css`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex-grow: 1;
  margin-top: 16rem;
  margin-bottom: 5rem;
  @media (max-width: 970px) {
    margin-top: 13rem;
    margin-bottom: 3rem;
  }
`;

const InsideContainer = css`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
  padding: 1rem 0rem;
  h1 {
    font-size: 2.2rem;
    margin-bottom: 0.2rem;
  }
  @media (max-width: 1033px) {
    text-align: center;
    padding: 1rem 1rem;
  }
  @media (max-width: 440px) {
    padding: 1rem 0rem;
    h1 {
      font-size: 1.6rem;
    }
  }
`;

const SubText = css`
  max-width: 1200px;
  color: white;
  font-size: 1.2rem;
  @media (max-width: 430px) {
    padding: 0px 25px;
    text-align: center;
  }
`;

const AssetContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 180px;

  img {
    margin: 0 10px;
    height: 190px;
    width: auto;
    margin-bottom: -105px;
  }
  @media (max-width: 1400px) {
    margin-right: 100px;
  }
  @media (max-width: 1050px) {
    margin: 0 auto;
    flex: 1;
    justify-content: center;
  }
  @media (max-width: 650px) {
    img {
      margin: 0 6px;
      // height: 95px;
      // width: auto;
      // margin-bottom: -55px;
      height: auto;
      width: 25%;
      margin-bottom: calc(100vw / -6.4);
    }
  }
`;

const BlueBackground = css`
  background: linear-gradient(to top, #1f90f0, #7fbff6);
`;

const WhyPartner = () => {
  return (
    <div className={BlueBackground}>
      <div className={Container}>
        <div className={MainContainer}>
          <div className={InsideContainer}>
            <h1>Why partner with us?</h1>
            <p className={SubText}>
              Since our inception in 2016, we have delivered curated recommendations to over 1,500
              people looking for the next best thing to do in Toronto. As a partner with ExploreTO
              Experiences, we will help you grow your local brand by bringing you to the forefront
              of our community, creating an effortless booking experience for your customers through
              our platform, and a customizable dashboard that lets you learn about your customers
              and know how your activities are doing.
            </p>
          </div>
        </div>
      </div>
      <div className={AssetContainer}>
        <img src={visibilityAsset} alt="Community Visibility" />
        <img src={bookingAsset} alt="Seamless Bookings" />
        <img src={insightAsset} alt="Sales Insights" />
      </div>
    </div>
  );
};

export default WhyPartner;
