import React, { useState, useEffect } from 'react';
import { css, cx } from 'emotion';
import axios from 'axios';
import { toJS } from 'mobx';
import ReactTable from 'react-table';
import { format } from 'date-fns';
import { useRootStore } from '../../stores/BridgeProvider';
import Spinner from '../Login/Spinner';

const container = css`
  padding: 0 2rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

  @media (max-width: 700px) {
    padding: 0 0.5rem;
  }
`;

const Experience = css`
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: 800px;
  border: 1px solid #666;
  margin: 1rem;
  width: 100%;
  max-width: 900px;
  min-width: 500px;
  img {
    height: 140px;
    width: 200px;
  }
  .contentContainer {
    padding: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #666;
    h3,
    h5 {
      margin: 0px;
    }
  }
  .packageContainer {
    flex: 1;
    display: flex;
    align-items: center;
    .pcName {
      margin-right: 5px;
    }
  }

  @media (max-width: 700px) {
    margin: 0.4rem;
    min-width: 340px;
    img {
      height: 100px;
      width: 120px;
    }
    .packageContainer {
      font-size: 0.8rem;
    }
  }
`;

const Experiences = () => {
  const [loading, setLoading] = useState(true);
  const [experiences, setExperiences] = useState([]);
  const { authStore, dataStore } = useRootStore();

  const vInfo = dataStore.vendorInfo;

  useEffect(() => {
    const getExperiences = async () => {
      try {
        const { data } = await axios.get(
          `https://frozen-woodland-21144.herokuapp.com/api/experiences?vendor=${vInfo._id}`
        );
        console.log(data);
        setExperiences(data.docs);
      } catch {}
    };

    const initialize = async () => {
      if (!vInfo) {
        await dataStore.retrieveVendorInfo();
      }
      await getExperiences();
      setLoading(false);
    };

    initialize();
  }, [dataStore, vInfo]);

  if (loading)
    return (
      <div style={{ marginTop: '2rem' }}>
        <Spinner color="#1e88e5" />
      </div>
    );

  return (
    <div className={container}>
      {experiences.map(({ photos, name, _id, packages }) => (
        <div className={Experience} key={_id}>
          <img src={photos[0]} alt="" />
          <div className="contentContainer">
            <h3>{name}</h3>
            <h5>Packages:</h5>
            {packages.map(({ name: pName, price, _id: p_id }) => (
              <div className="packageContainer" key={p_id}>
                <span className="pcName">{pName}</span>
                <span>${price.value}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Experiences;
