import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import Home from '../components/Home';
import ScrollToTop from '../util/ScrollToTop';
import Login from '../components/Login';
import GetStarted from '../components/GetStarted';
import AuthRoute from './AuthRoute';
import UnAuthRoute from './UnAuthRoute';
import SalesHistory from '../components/SalesHistory';
import MyAccount from '../components/MyAccount';
import Sidebar from '../components/Sidebar';

const Routes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <div>
        <Switch>
          <UnAuthRoute exact path="/" component={Home} />
          <UnAuthRoute exact path="/login" component={Login} />
          <UnAuthRoute exact path="/get-started" component={GetStarted} />

          <AuthRoute exact path="/sales-history">
            <Sidebar>
              <SalesHistory />
            </Sidebar>
          </AuthRoute>
          <AuthRoute exact path="/account">
            <Sidebar>
              <MyAccount />
            </Sidebar>
          </AuthRoute>
          <Redirect to="/" />
        </Switch>
      </div>
    </ScrollToTop>
  </BrowserRouter>
);

export default Routes;
