import React from 'react';

const rootContext = React.createContext();

export const BridgeProvider = ({ stores, children }) => (
  <rootContext.Provider value={stores}>{children}</rootContext.Provider>
);

export const useRootStore = () => {
  return React.useContext(rootContext);
};
