import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Observer } from 'mobx-react';
import { useRootStore } from '../stores/BridgeProvider';

const AuthRoute = ({ component: PassedComponent, ...rest }) => {
  const { authStore } = useRootStore();

  //   useEffect(() => {
  //     const { isAuthenticated } = authStore;
  //     if (isAuthenticated) orgStore.retrieveActiveOrg();
  //   }, [authStore, orgStore]);

  return (
    <Observer
      render={() => {
        const { isAuthenticated } = authStore;
        //TODO: revisit why this broke
        if (!isAuthenticated) return <Redirect to={{ pathname: '/login' }} />;
        return (
          <Route
            {...rest}
            render={(props) =>
              isAuthenticated === true ? (
                <PassedComponent {...props} />
              ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
              )
            }
          />
        );
      }}
    />
  );
};

export default AuthRoute;
