import React from 'react';
import { css } from 'emotion';
import Card from './misc/Card';
import skydive from './assets/skydive.jpg';
import yoga from './assets/Yoga.jpg';
import pottery from './assets/pottery.jpg';
import cooking from './assets/cooking.jpeg';

const Container = css`
  // height: 100vh;
  background: white;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const MainContainer = css`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-bottom: 0rem;
  flex-grow: 1;
`;

const CardContainerGradient = css`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 0rem;
  flex-grow: 1;
  @media (max-width: 1048px) {
    background: linear-gradient(to top, #7fbff6, #fff);
  }
`;

const InsideContainer = css`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
  padding: 1rem 0rem;
  h1 {
    font-size: 2.2rem;
    margin-bottom: 0.2rem;
  }
  button {
    color: white;
    background: rgb(30, 136, 229);
    border-radius: 2rem;
    font-size: 1.5rem;
    padding: 1.3rem 4rem;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
    border: none;
    margin: 0px 15px;
  }
  @media (max-width: 1048px) {
    text-align: center;
    padding: 1rem 1rem;
  }
  @media (max-width: 440px) {
    padding: 1rem 0rem;
    h1 {
      font-size: 1.6rem;
    }
  }
`;

const SubText = css`
  max-width: 1200px;
  font-size: 1.2rem;
  color: #4e4e4e;
  @media (max-width: 430px) {
    padding: 0px 25px;
    text-align: center;
  }
`;

const CardsContainer = css`
  margin-top: 4rem;
  margin-bottom: -187px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  align-self: flex-start;
  @media (max-width: 1048px) {
    align-self: center;
    justify-content: center;
    max-width: 600px;
    .home-activity-card {
      margin: 10px;
    }
  }
  @media (max-width: 970px) {
    margin-top: 2.5rem;
  }
`;

const Introducing = () => {
  return (
    <div className={Container}>
      <div className={MainContainer}>
        <div className={InsideContainer}>
          <h1>Introducing ExploreTO Experiences</h1>
          <p className={SubText}>
            We are partnering with local small businesses to curate unique experiences for
            Torontonians, through activity vouchers they can purchase directly through the
            application. From hands-on workshops to wellness retreats, there is something for
            everyone to experience in Toronto.
          </p>
        </div>
      </div>
      <div className={CardContainerGradient}>
        <div className={CardsContainer}>
          <Card
            title="Action & Adventure"
            text="Take adrenaline junkies on the ultimate thrill ride through water sports, skydiving, and much more."
            url={skydive}
          />
          <Card
            title="Food & Drinks"
            text="Are you a coffee connoisseur? Does your grandmother's pasta recipe spark envy in your friends? Share your culinary expertise with the ExploreTO community!"
            url={cooking}
          />
          <Card
            title="Health & Wellness"
            text="Lead fitness gurus on the ultimate health journey through unique activities like aerial yoga, guided meditation, and more."
            url={yoga}
          />
          <Card
            title="Arts & Culture"
            text="From jewelry making and comedy shows, to historic walking tours, give Torontonians a cultural experience they will never forget!"
            url={pottery}
          />
        </div>
      </div>
    </div>
  );
};

export default Introducing;
