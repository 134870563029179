import React from 'react';
import { css } from 'emotion';
import backgroundBlur from './assets/backgroundBlur.jpg';
import screenshot from './assets/screenshot.png';

const Container = css`
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundBlur});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  @media (max-width: 970px) {
    height: 700px;
    margin-top: 60px;
  }
  @media (max-width: 590px) {
    height: 500px;
  }
`;

const InsideContainer = css`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  align-items: center;
  padding-right: 20%;
  padding-left: 2%;
  max-width: 1050px;
  margin: auto;
  h1 {
    font-size: 4rem;
    margin-top: 1rem;
    font-family: BebasNeue;
    font-weight: 100;
  }
  button {
    color: white;
    background: rgb(30, 136, 229);
    border-radius: 2rem;
    font-size: 1.5rem;
    padding: 1.3rem 4rem;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
    border: none;
    margin: 0px 13px 0px 13px;
  }

  @media (max-width: 970px) {
    padding: 1rem 2rem;
    text-align: center;
    h1 {
      font-size: 3rem;
    }
  }
  @media (max-width: 530px) {
    padding: 1rem 1.5rem;
    h1 {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 370px) {
    button {
      font-size: 1.2rem;
    }
  }
`;

const screenshotImage = css`
  height: 560px;
  position: absolute;
  bottom: 0;
  right: 8%;
  @media (max-width: 1332px) {
    right: 4%;
    height: 480px;
  }
  @media (max-width: 970px) {
    display: none;
  }
`;

const SubText = css`
  color: rgb(30, 136, 229);
  margin-bottom: 0px;
  font-size: 1.5rem;
  @media (max-width: 430px) {
    padding: 0px 25px;
    text-align: center;
  }
`;

const StoreContainer = css`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  @media (max-width: 970px) {
    justify-content: center;
  }
`;

const whiteButton = css`
  background: white !important;
  color: rgb(30, 136, 229) !important;
  @media (max-width: 590px) {
    display: none;
  }
`;

const Intro = () => {
  return (
    <div className={Container}>
      <div className={InsideContainer}>
        <section>
          <h3 className={SubText}>EXPLORETO PARTNERS</h3>
          <h1 style={{ color: 'white' }}>
            HOST YOUR LOCAL EXPERIENCE-BASED ACTIVITIES ON EXPLORETO.
          </h1>
          <div className={StoreContainer}>
            <button>Get Started</button>
            <button className={whiteButton}>Learn More</button>
          </div>
        </section>
      </div>
      <img src={screenshot} alt="screenshot" className={screenshotImage} />
    </div>
  );
};

export default Intro;
