import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { css } from 'emotion';
import { useRootStore } from '../../stores/BridgeProvider';
import { toJS } from 'mobx';
const Container = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InsideContainer = css`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  align-items: center;
  padding: 0px 15px;
`;

const FormCard = css`
  height: auto;
  width: 100%;
  max-width: 1000px;
  background: white;
  border-radius: 1rem;
  padding: 0.5rem;
  text-align: left;
  form {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 3rem;
    @media (min-width: 500px) {
      section:nth-child(even) {
        padding-left: 10px;
      }
      section:nth-child(odd) {
        padding-right: 10px;
      }
    }
  }
  section {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0.8rem 0rem;
    width: 100%;
  }
  input {
    outline: none;
    font-size: 1rem;
    padding: 0.5rem 8px;
    border-radius: 0.3rem;
    border: 1px solid #666;
  }
  label {
    margin-bottom: 7px;
    font-family: 'AvenirMedium';
    color: #666;
  }
  button {
    background: white;
    border-color: rgb(30, 136, 229);
    border-width: 1px;
    color: rgb(30, 136, 229);
    border-radius: 1rem;
    font-size: 1.2rem;
    padding: 0.4rem;
    outline: none;
    cursor: pointer;
    width: 160px;
    margin-top: 2rem;
    border-style: solid;
    border-image: none;
    @media (max-width: 500px) {
      margin: 0 auto;
    }
  }
  .formRow {
    display: flex;
    flex-direction: row;
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
`;

const BusinessTab = () => {
  const [loading, setLoading] = useState(true);
  const { authStore, dataStore } = useRootStore();
  const { userToken } = authStore;

  const vInfo = dataStore.vendorInfo;

  useEffect(() => {
    const initialize = async () => {
      if (!vInfo) {
        await dataStore.retrieveVendorInfo();
      }
      setLoading(false);
    };
    initialize();
  }, [dataStore, vInfo]);

  if (loading) return null;
  console.log(toJS(vInfo));

  return (
    <section className={Container}>
      <section className={InsideContainer}>
        <section className={FormCard}>
          <form>
            <div className="formRow">
              <section>
                <label>Best time to call</label>
                <input type="text" />
              </section>
            </div>
            <div className="formRow">
              <section>
                <label>Business Name *</label>
                <input type="text" />
              </section>
              <section>
                <label>Postal Code</label>
                <input type="text" />
              </section>
            </div>
            <button>Save</button>
          </form>
        </section>
      </section>
    </section>
  );
};

export default BusinessTab;
