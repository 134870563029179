import React, { useState } from 'react';
import { css, cx } from 'emotion';
// import axios from 'axios';
import Sidebar from '../Sidebar';

import ReactTable from 'react-table';
import { format } from 'date-fns';

const DUMMY_DATA = [
  {
    invoiceNumber: '0012345',
    invoicePeriod: 'Aug 1 - Aug 15',
    amountDue: '190',
    datePaid: new Date(),
  },
  {
    invoiceNumber: '0013345',
    invoicePeriod: 'Aug 15 - Aug 30',
    amountDue: '370',
    datePaid: new Date(),
  },
];

// const container = css`
//   padding: 0 2rem;

//   @media (max-width: 700px) {
//     padding: 0 5px;
//   }

//   padding-top: 2rem;

//   .ReactTable {
//     border: none;
//     color: #666;
//   }

//   .ReactTable .rt-table {
//     overflow: visible;
//   }

//   .rt-tbody {
//     overflow: visible !important;
//   }

//   .rt-tr {
//     border: none !important;
//     background: white;
//     margin: 0.5rem 0px;
//     padding: 1rem 0.5rem;
//     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);
//     // align-items: center;
//   }

//   .rt-tr-group {
//     border: none !important;
//   }
//   .rt-td {
//     border: none !important;
//   }

//   .rt-thead {
//     border: none !important;
//     box-shadow: none !important;
//     overflow: visible !important;
//     .rt-tr {
//       box-shadow: none !important;
//     }
//   }

//   .rt-th {
//     text-align: left;
//     border-right: none !important;
//   }
// `;

const container = css`
  padding: 0 2rem;
  @media (max-width: 700px) {
    padding: 0 5px;
  }

  .topItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ReactTable {
    border: none;
    color: #666;
  }

  .ReactTable .rt-table {
    overflow: visible;
  }

  .rt-tr {
    border: none !important;
    background: white;
    margin: 0.5rem 0px;
    padding: 1rem 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);
    align-items: center;
  }

  .rt-tr-group {
    border: none !important;
  }
  .rt-td {
    border: none !important;
  }

  .rt-tbody {
    overflow: visible !important;
  }

  .rt-thead {
    border: none !important;
    box-shadow: none !important;
    overflow: visible !important;
    .rt-tr {
      padding: 0px !important;
    }
    .rt-th {
      height: 60px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
`;

const PaymentTab = () => {
  const columns = [
    {
      Header: 'Invoice #',
      accessor: 'invoiceNumber',
    },
    {
      Header: 'Invoice Period',
      accessor: 'invoicePeriod',
    },
    {
      Header: 'Amount Due',
      accessor: (d) => `$${parseFloat(d.amountDue).toFixed(2)}`,
      id: 3,
    },
    {
      Header: 'Date Paid',
      accessor: (d) => format(d.datePaid, 'MM/dd/yyyy'),
      id: 4,
    },
  ];
  return (
    <div className={container}>
      <div>
        <ReactTable
          data={DUMMY_DATA}
          columns={columns}
          minRows={1}
          showPagination={false}
          //   filterable
          //   defaultFilterMethod={(filter, row) => {
          //     const id = filter.pivotId || filter.id;
          //     return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
          //   }}
        />
      </div>
    </div>
  );
};

export default PaymentTab;
