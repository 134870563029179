import React from 'react';
import { FaFacebookF, FaInstagram, FaRedditAlien } from 'react-icons/fa';
import { css } from 'emotion';
import { withRouter } from 'react-router-dom';

const FooterStyle = css`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  //position: fixed;
  bottom: 0;
  //   width: 100%;
  flex: 1;
  a {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    margin: 0px 8px;
  }
  span {
    color: white;
    font-size: 0.8rem;
  }
`;

const Footer = () => {
  const backgroundStyle = { background: '#3592e4' };
  const displayFooter = null;
  return (
    <footer className={FooterStyle} style={{ ...backgroundStyle, ...displayFooter }}>
      <div>
        <span>© ExploreTO 2019</span>
      </div>
      <div>
        <a
          href="https://www.instagram.com/exploretoapp/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
        <a href="https://www.facebook.com/ExploreTOApp/" target="_blank" rel="noopener noreferrer">
          <FaFacebookF />
        </a>
        <a
          eventLabel="Reddit"
          href="https://www.reddit.com/u/exploretoapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaRedditAlien />
        </a>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
