import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes/routes';
import * as serviceWorker from './serviceWorker';
import { BridgeProvider } from './stores/BridgeProvider';
import AuthStore from './stores/authStore';
import DataStore from './stores/dataStore';

const dataStore = new DataStore();

ReactDOM.render(
  <BridgeProvider stores={{ authStore: AuthStore, dataStore }}>
    <Routes />
  </BridgeProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
