import { observable, computed, action } from 'mobx';
import axios from 'axios';

class AuthStore {
  @observable userToken = null;
  @observable userName = null;
  @observable userEmail = null;
  @observable authenticating = true;

  constructor() {
    const userObj = JSON.parse(window.localStorage.getItem('userInfo'));
    if (userObj) {
      this.setLoginInfo(userObj);
    }
  }

  getUserToken = () => {
    return this.userToken;
  };

  @action
  setUserToken = (newToken) => {
    this.userToken = newToken;
  };

  @action
  onLogin = (data, saveSignIn) => {
    console.log(saveSignIn);
    this.setLoginInfo(data);
    saveSignIn && window.localStorage.setItem('userInfo', JSON.stringify(data));
  };

  @action
  onLogout = () => {
    this.userToken = null;
    this.userName = null;
    this.userEmail = null;
    window.localStorage.setItem('userInfo', null);
    console.log('hello');
  };

  @action
  setLoginInfo = ({ name, email, token }) => {
    this.userToken = token;
    this.userName = name;
    this.userEmail = email;
  };

  // startAutoRenewSession = () => {
  //   setInterval(() => {
  //     this.renewSession();
  //   }, 1000 * 60 * 31);
  // };

  //   setSession = authResult => {
  //     this.startAutoRenewSession();
  //     // Set isLoggedIn flag in localStorage
  //     localStorage.setItem('isLoggedIn', 'true');

  //     // Set the time that the access token will expire at
  //     const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
  //     this.accessToken = authResult.accessToken;
  //     this.idToken = authResult.idToken;
  //     this.email = authResult.idTokenPayload.email;
  //     this.expiresAt = expiresAt;

  //     // send Token info to server
  //     this.sendServerEmail();
  //   };

  //   renewSession = () => {
  //     this.authenticating = true;
  //     this.auth0.checkSession({}, async (err, authResult) => {
  //       if (authResult && authResult.accessToken && authResult.idToken) {
  //         this.setSession(authResult);
  //       } else if (err) {
  //         this.logout();
  //         console.error(err);
  //         alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
  //       }
  //       this.authenticating = false;
  //     });
  //   };

  //   logout = () => {
  //     // Remove tokens and expiry time
  //     this.accessToken = null;
  //     this.idToken = null;
  //     this.email = null;
  //     this.expiresAt = 0;

  //     // Remove isLoggedIn flag from localStorage
  //     localStorage.removeItem('isLoggedIn');

  //     this.auth0.logout({
  //       returnTo: window.location.origin
  //     });
  //   };

  @computed
  get request() {
    const token = this.getUserToken();
    return {
      get: (url, options = {}) =>
        axios.get(url, {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
          ...options,
        }),
      post: (url, data, options = {}) =>
        axios.post(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
          ...options,
        }),
      put: (url, data, options = {}) =>
        axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
          ...options,
        }),
      delete: (url, options = {}) =>
        axios.delete(url, {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
          ...options,
        }),
    };
  }

  //   @computed get isAuthenticated() {
  //     // Check whether the current time is past the
  //     // access token's expiry time
  //     const { expiresAt } = this;
  //     return new Date().getTime() < expiresAt;
  //   }

  @computed get isAuthenticated() {
    return this.userToken != null;
  }

  @action
  stopAuthenticating = () => {
    this.authenticating = false;
  };
}

const auth = new AuthStore();

export default auth;
