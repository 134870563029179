import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import Modal from 'react-modal';
import { format } from 'date-fns';
import { FaTimes } from 'react-icons/fa';
import { toJS } from 'mobx';
import axios from 'axios';
import { useRootStore } from '../../stores/BridgeProvider';
import { useWindowSize } from '../../util/CustomHooks';

const modalContainer = css`
  height: 500px;
  width: 400px;
  padding: 2rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(204, 204, 204);
  position: relative;
  background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;



  .modalClose {
    position: absolute;
   right: 8px;
    top: 8px;
    cursor: pointer;
    font-size: 1.5rem;
    border: none;
    color: #9a9a9a;
    outline: 0;
      padding-top: 5px;
  }
  input{
    outline: none;
    font-size: 1rem;
    padding: 8px 10px;
    min-width: 200px;
     border: 1px solid #cecece;
     margin-bottom: 1rem;
  }
  h2 {
    color: #3b94e1;
  }
  h5 {
    color: #9a9a9a;
  }
  table {
    border-spacing: 0 0.7rem;

    td,
    th {
      border-left: 30px solid #fff;
    }

    tr > td:first-of-type {
      border: 0px;
      color: #afaeae;
    }
  }
  .modalConfirmButton {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    button {
      outline: none;
      cursor: pointer;
      border: none;
      color: white;
      background: #0f93e8;
      border-radius: 0.4rem;
      font-size: 1.2rem;
      padding: 0.6rem 1.5rem;
      &:disabled{
        opacity: 0.5;
        cursor: auto;
      }
    }
  }

   @media (max-width: 700px) {
    height: auto;
    padding: auto;
  }
  }
`;

const packageContainer = css`
  margin-bottom: 2rem;
  .packageDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const incrementerStyle = css`
  display: flex;
  align-items: center;
  input {
    width: 30px;
    min-width: 0px;
    height: 30px;
    margin: 0px;
    padding: 0px 5px;
    border-left: 0px;
    border-right: 0px;
    text-align: center;
  }
  button {
    height: 32px;
    margin: 0px;
  }
`;

const Incrementer = ({ item, changeValue, index }) => {
  const { count, redeem } = item;
  const minDisabled = redeem === 0;
  const maxDisabled = redeem >= count ? true : false;
  return (
    <div className={incrementerStyle}>
      <button disabled={minDisabled} onClick={() => changeValue(index, redeem - 1)}>
        -
      </button>
      <input type="number" max={count} min={0} value={redeem} disabled />
      <button disabled={maxDisabled} onClick={() => changeValue(index, redeem + 1)}>
        +
      </button>
    </div>
  );
};

const priceFromCart = (cart) => {
  //FIXME: see if Ivan can return price
  //FIXME: sale price
  const sum = cart.reduce(function (accumulator, currentValue, currentIndex, array) {
    const { count, package: curPackage } = currentValue;
    const packageValue = count * curPackage.price.value;
    return accumulator + packageValue;
  }, 0);
  return `$${parseFloat(sum).toFixed(2)}`;
};

const SaleModal = ({ open, controlOpen, sale }) => {
  const [search, setSearch] = useState('');
  const [redeemCart, setRedeemCart] = useState([]);
  const { authStore, dataStore } = useRootStore();
  const screenWidth = useWindowSize();

  const sharedCustom = {
    top: '50%',
    bottom: 'auto',
    justifyContent: 'center',
    background: 'transparent',
    border: 'none',
  };

  const customStyles = {
    content:
      screenWidth >= 1080
        ? {
            ...sharedCustom,
            //Taking into account sidebar
            left: 'calc(130px + 50%)',
            right: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          }
        : {
            ...sharedCustom,
            transform: 'translate(0%, -50%)',
            height: 'auto',
            display: 'flex',
          },
  };

  useEffect(() => {
    setSearch('');
    if (sale && sale.cart) {
      const cartWithRedeem = sale.cart.map((cur) => ({ ...cur, redeem: 0 }));
      setRedeemCart(cartWithRedeem);
    }
  }, [open, sale]);
  if (!sale) return null;

  const { cart, customer, experience, dateCreated, shortID } = sale;

  const { name: experienceName } = experience || {};
  const { name: customerName } = customer || {};

  const formattedDateCreated = dateCreated ? format(new Date(dateCreated), 'yyyy/dd/MM') : 'N/A';

  const updateCart = (index, redeem) => {
    const updatedCart = [...redeemCart];
    updatedCart[index].redeem = redeem;
    setRedeemCart(updatedCart);
  };

  const redeemVoucher = async () => {
    try {
      const request = `https://frozen-woodland-21144.herokuapp.com/app/transactions/redeem/${search}?token=${authStore.userToken}`;
      console.log(request);
      const redeemArray = redeemCart
        .filter((item) => item.redeem > 0)
        .map((item) => ({
          _id: item.package._id,
          redeem: item.redeem,
        }));
      await axios.put(request, {
        redeem: redeemArray,
      });
      dataStore.retrieveExperienceTransactions();
      controlOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  console.log(toJS(redeemCart));

  const hasASelectedRedeemPackage = redeemCart.some(({ redeem }) => redeem > 0);

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => controlOpen(false)}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={modalContainer}>
        <div>
          <button className="modalClose" onClick={() => controlOpen(false)}>
            <FaTimes />
          </button>
          <h2>Redeem Voucher</h2>
          <h5>VOUCHER DETAILS</h5>
          <table>
            <tbody>
              <tr>
                <td>Transaction #</td>
                <td>{shortID}</td>
              </tr>
              <tr>
                <td>Customer Name </td>
                <td>{customerName}</td>
              </tr>
              <tr>
                <td>Activity Name </td>
                <td>{experienceName}</td>
              </tr>
              <tr>
                <td>Date Purchased </td>
                <td>{formattedDateCreated}</td>
              </tr>
              <tr>
                <td>Total Paid </td>
                {/* <td>${parseFloat(totalPaid).toFixed(2)}</td> */}
                {/* FIXME:  */}
                <td>{priceFromCart(cart)}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <h5>REDEEMABLE PACKAGES</h5>
            <div className={packageContainer}>
              {redeemCart.map((cartItem, index) => {
                const { package: cPackage } = cartItem;
                return (
                  <div key={cPackage._id} className="packageDiv">
                    <span>{cPackage.name}</span>

                    <Incrementer item={cartItem} index={index} changeValue={updateCart} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modalConfirmButton">
            <input
              type="text"
              placeholder="Enter code or email"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              disabled={search.length < 1 || !hasASelectedRedeemPackage}
              onClick={redeemVoucher}
            >
              Redeem Voucher
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SaleModal;
