import React from 'react';
import { css } from 'emotion';
import Step from './misc/Step';
import { Link } from 'react-router-dom';

const Container = css`
  // height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  margin-top: 6rem;
`;

const MainContainer = css`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex-grow: 1;
  margin-bottom: 3rem;
`;

const InsideContainer = css`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
  padding: 1rem 0rem;
  h1 {
    font-size: 2.2rem;
    margin-bottom: 0.2rem;
  }
  button {
    color: white;
    background: rgb(30, 136, 229);
    border-radius: 2rem;
    font-size: 1.5rem;
    padding: 1.3rem 4rem;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
    border: none;
    margin: 0px 15px;
  }
  @media (max-width: 1033px) {
    text-align: center;
    padding: 1rem 1rem;
  }
  @media (max-width: 440px) {
    padding: 1rem 0rem;
    h1 {
      font-size: 1.6rem;
    }
  }
`;

const SubText = css`
  max-width: 1200px;
  font-size: 1.2rem;
  color: #4e4e4e;
  @media (max-width: 430px) {
    padding: 0px 25px;
    text-align: center;
  }
`;

const StoreContainer = css`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    color: white;
    background: rgb(30, 136, 229);
    border-radius: 2rem;
    font-size: 1.5rem;
    padding: 1.3rem 4rem;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
    border: none;
    text-decoration: none;
  }
  @media (max-width: 1080px) {
    margin-top: 0.5rem;
  }
`;

const StepContainer = css`
  display: flex;
  justify-content: space-around;
  margin: 6rem 0px;

  .yellow-strip {
    position: absolute;
    width: 100%;
    height: 60px;
    border-style: solid;
    border-color: #fcdd8a;
    background-color: #fcdd8a;
    z-index: 1;
    margin-top: 50px;
  }

  @media (max-width: 1080px) {
    .yellow-strip {
      display: none;
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0px;
  }
`;

const HowToStart = () => {
  return (
    <div className={Container}>
      <div className={MainContainer}>
        <div className={InsideContainer}>
          <h1>How to Get Started</h1>
          <p className={SubText}>
            The journey to become an Experiences Partner is quite simple. If you're a local Toronto
            business specializing in fun and unique activities, here's what to expect when
            partnering with us.
          </p>
        </div>
        <div className={StepContainer}>
          <div className="yellow-strip" />
          <Step number={1} text="Connect with us by filling out our Partner Inquiry form." />
          <Step number={2} text="Connect with us by filling out our Partner Inquiry form." />
          <Step number={3} text="Connect with us by filling out our Partner Inquiry form." />
          <Step number={4} text="Connect with us by filling out our Partner Inquiry form." />
        </div>
        <div className={StoreContainer}>
          <Link to="/get-started">Get Started</Link>
        </div>
      </div>
    </div>
  );
};

export default HowToStart;
