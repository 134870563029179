import React from 'react';
import { css, cx } from 'emotion';

const Container = css`
  height: 380px;
  width: 250px;
  background: white;
  border-radius: 1.2rem;
  overflow: hidden;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  margin-bottom: 0px;
}
`;

const TextContainer = css`
  margin: 5px 1.5rem;
  h3 {
    color: #4e4e4e;
    margin-bottom: 10px;
  }
  p {
    margin-top: 5px;
    color: #4e4e4e;
    font-size: 0.85rem;
  }
`;

const Card = ({ text, title, url }) => {
  const Image = css`
    height: 210px;
    width: 250px;
    background: url(${url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `;
  return (
    <div className={cx(Container, 'home-activity-card')}>
      <div className={Image} />
      <div className={TextContainer}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Card;
